body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f8fa;
}

:root {
  --ant-font-family: 'Poppins', Arial, sans-serif;
}
  
  body {
    font-family: var(--ant-font-family);
  }
  
  .ant-btn,
  .ant-input,
  .ant-select,
  .ant-modal-content,
  .ant-menu,
  .ant-table {
    font-family: var(--ant-font-family);
  }